import BackgroundImage from "gatsby-background-image";
import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import { Monetization } from "../../../lib/model/monetization";
import { Container } from "../../Shared/Container/Container";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { Monetization as Mone } from "../../Shared/Monetization/Monetization";
import { SectionHeading } from "../../Shared/Typography/SectionHeading";

export interface MonetizationHeroProps extends Monetization {
  className?: string;
  image: FluidObject;
  Gallery: any[];
}

const Label = ({ children, className = "" }) => (
  <div className={`font-bold text-lg ${className}`}>{children}</div>
);

const Row = ({ label, value, className = "" }) => (
  <div className={` ${className} flex items-center w-full mb-1`}>
    <Label className="w-3/6 text-primary">{label}</Label>
    <div className="w-3/6 text-primary">{value}</div>
  </div>
);

export const MonetizationHero = (props: MonetizationHeroProps) => {
  const {
    className = "",
    image,
    name,
    address,
    ico,
    fileNumber,
    region,
    monetizationType,
    status,
    Gallery,
    description,
  } = props;

  return (
    <section className={`className`}>
      <Container>
        <BackgroundImage
          fluid={image}
          style={{
            height: 420,
          }}
        >
          <div className="hidden wrapper w-full h-full md:flex justify-end items-end">
            <div className="p-3 lg:p-8 h-auto flex bg-white bg-opacity-90 border-b-4 border-secondary w-full md:w-8/12 lg:w-7/12 2xl:w-5/12 flex-col">
              <Row className="pb-4" label="Úpadca:" value={name} />
              <Row label="Sídlo:" value={address} />
              <Row label="IČO:" value={ico} />
              <Row
                className="pb-4"
                label="Spisová značka:"
                value={fileNumber}
              />
              <Row label="Kraj:" value={region} />
              <Row label="Forma speňaženia:" value={monetizationType} />
              <Row label="Stav speňaženia:" value={status} />
            </div>
          </div>
        </BackgroundImage>
        <div className="content">
          <div className="flex md:hidden p-3 lg:p-8 h-auto bg-white border-b-4 border-secondary w-full md:w-8/12 lg:w-7/12 2xl:w-5/12 flex-col">
            <Row className="pb-4" label="Úpadca:" value={name} />
            <Row label="Sídlo:" value={address} />
            <Row label="IČO:" value={ico} />
            <Row className="pb-4" label="Spisová značka:" value={fileNumber} />
            <Row label="Kraj:" value={region} />
            <Row label="Forma speňaženia:" value={monetizationType} />
            <Row label="Stav speňaženia:" value={status} />
          </div>

          <div className="gallery mt-4 gap-4 grid grid-cols-4 ">
            {Gallery.map(({ image }, index) => (
              <div key={index} className="h-40 overflow-hidden">
                <GatsbyImage fluid={image.childImageSharp?.fluid} />
              </div>
            ))}
          </div>

          <div className="text mt-6">
            <SectionHeading>{name}</SectionHeading>
            <ContentRenderer source={description} />
          </div>
        </div>
      </Container>
      <div className="monetization mt-10 py-16 bg-section">
        <Container>
          <Mone />
        </Container>
      </div>
    </section>
  );
};
