import { graphql } from "gatsby";
import React from "react";
import { Hero } from "../../components/Home/Hero/Hero";
import { MonetizationHero } from "../../components/Monetization/MonetizationHero/MonetizationHero";
import Layout from "../../components/Shared/Layout/Layout";
import { Monetization } from "../../components/Shared/Monetization/Monetization";
import { SEO } from "../../components/Shared/SEO/SEO";

export const query = graphql`
  query getMonetization($slug: String) {
    strapiMonetization(slug: { eq: $slug }) {
      address
      description
      fileNumber
      heroImage {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      name
      monetizationType
      ico
      subject
      strapiId
      status
      region
      slug
      Gallery {
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

const MonetizationPage = ({ data: { strapiMonetization } }) => {
  console.log(strapiMonetization);

  return (
    <Layout>
      <SEO description="This is an index page" />
      <div className="content space-y-24 mb-24">
        <MonetizationHero
          {...strapiMonetization}
          image={strapiMonetization.heroImage.childImageSharp.fluid}
        />
      </div>
    </Layout>
  );
};

export default MonetizationPage;
